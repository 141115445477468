<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        @click="openModal()"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :columns="columns"
      :resource="resource"
      :filters="filters"
      hide-per-page
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <money-bonus-editor :bonus="selectedBonus" @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import {
  VxTable,
  VxCellDate,
  VxCellMoney,
  VxCellButtons,
  VxCellBadges
} from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { bonuses, useResource } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { ref, computed } from '@vue/composition-api'
import { BModal } from 'bootstrap-vue'
import MoneyBonusEditor from '@/views/bonuses/MoneyBonusEditor'

export default {
  name: 'MoneyBonuses',

  components: {
    VxTable,
    VxButton,
    VxPageTitleActions,
    VxCellDate,
    VxCellButtons,
    VxCellMoney,
    MoneyBonusEditor,
    BModal
  },
  setup () {
    const tableRef = ref(null)

    const resource = bonuses.money

    const { can: canCreate } = useResource(bonuses.moneyCreate)
    const { can: canUpdate } = useResource(bonuses.moneyUpdate)

    const modalIsOpen = ref(false)
    const selectedBonus = ref(null)
    const modalTitle = computed(() => {
      return (selectedBonus.value ? 'Edit' : 'Create') + ' Bonus'
    })

    const openModal = (bonus = null) => {
      selectedBonus.value = bonus
      modalIsOpen.value = true
    }

    const closeModal = () => {
      modalIsOpen.value = false
      selectedBonus.value = null
    }

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const columns = [
      {
        key: 'id',
        label: 'ID',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'name',
        label: 'Name',
        sortable: true
      },
      {
        key: 'description',
        label: 'Description'
      },
      {
        key: 'amount',
        label: 'Amount',
        component: VxCellMoney
      },
      {
        key: 'amount.currency',
        label: 'Currency',
        component: VxCellBadges
      },
      {
        key: 'created_at',
        label: 'Created at',
        component: VxCellDate
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: {
          buttons: [
            {
              ...buttons.edit,
              can: canUpdate,
              onClick: openModal
            }
          ]
        }
      }
    ]
    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'name',
        type: filterTypes.text
      },
      {
        key: 'currency_code',
        label: 'Currency',
        type: filterTypes.text
      }
    ]

    return {
      tableRef,
      resource,
      columns,
      filters,
      canCreate,

      selectedBonus,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
