<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.name"
      :rules="rules.name"
      name="name"
      label="Name"
    />

    <vx-input
      v-model="formValue.description"
      name="description"
      label="Description"
    />

    <vx-input
      v-model="formValue.amount"
      :rules="rules.amount"
      name="amount"
      label="Amount"
    />

    <vx-select-resource
      v-model="formValue.currency_code"
      :rules="rules.currency_code"
      :resource="currenciesResource"
      :reduce="({ code }) => code"
      options-label="code"
      name="currency_code"
      label="Currency"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { reactive } from '@vue/composition-api'
import { setValuesToForm } from '@/services/form'
import { VxForm, VxInput, VxSelectResource } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { passDataToResource, bonuses, currencies } from '@/services/resources'
import { formatToCents, formatToDecimal } from '@/services/utils'

export default {
  name: 'MoneyBonusEditor',
  components: {
    VxSelectResource,
    VxForm,
    VxInput,
    VxButton,
    BModal
  },
  props: {
    bonus: {
      type: Object,
      default: null
    }
  },
  setup ({ bonus }, { emit }) {
    const isEdit = !!bonus

    const currenciesResource = currencies.getAll

    const frontToBackMapper = ({ amount, name, description, currency_code }) => {
      return {
        amount: formatToCents(amount),
        name,
        description,
        currency_code
      }
    }

    const backToFrontMapper = ({ amount: { amount, currency }, name, description }) => {
      return {
        amount: formatToDecimal(amount),
        currency_code: currency,
        name,
        description
      }
    }

    const formResource = isEdit
      ? passDataToResource(bonuses.moneyUpdate, {
        frontToBackMapper,
        requestParams: { urlParams: { id: bonus.id } }
      })
      : passDataToResource(bonuses.moneyCreate, {
        frontToBackMapper
      })

    const formValue = reactive({
      name: '',
      description: '',
      amount: 1,
      currency_code: 'USD'
    })

    const rules = {
      name: {
        required: true,
        min: 5
      },
      amount: {
        required: true,
        min: 1,
        numeric: true
      },
      currency_code: {
        required: true
      }
    }

    if (isEdit) {
      setValuesToForm(backToFrontMapper(bonus), formValue)
    }

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    return {
      formValue,
      formResource,
      rules,
      handleSubmit,
      currenciesResource
    }
  }
}
</script>
